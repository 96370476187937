import { ChangeDetectionStrategy, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { RouterModule } from '@angular/router';
import { Dialog } from '@capacitor/dialog';
import { openEmbeddedBrowser } from '@globals';
import { toAzureAdB2c } from '@language';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { selectNotification, selectShowNotificationsModal } from '@store/digi.me';
import { filter, take } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-notifications-modal',
  imports: [SharedModule, NgbModalModule, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '',
})
export class NotificationsModalComponent implements OnInit {
  readonly showNotificationsModal$ = this.store.select(selectShowNotificationsModal);
  readonly notification$ = this.store.select(selectNotification);

  locale = toAzureAdB2c($localize.locale);

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit(): void {
    this.showNotificationsModal$
      .pipe(
        filter((show) => show),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.showConfirm();
      });
  }

  showConfirm = () => {
    this.notification$.pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe(async (notification) => {
      if (notification) {
        await Dialog.alert({
          title: notification.messageTitle?.[this.locale] || '',
          message: notification.messageBody?.[this.locale] || '',
          buttonTitle: notification.messageButton?.[this.locale] || '',
        }).finally(() => {
          if (notification.actionUrl) {
            openEmbeddedBrowser(notification.actionUrl).finally(() => {
              this.showConfirm();
            });
          }
        });
      }
    });
  };
}
