import { Component } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AuthenticationService } from '@core/services';
import { MenuService } from '@navigation/menu';
import { Store } from '@ngrx/store';
import { selectErrorMessage, selectShowSecurityPromptModal } from '@store/app';
import {
  selectIsAccountDeleted,
  selectIsAccountDeleting,
  selectIsResetLoading,
  selectShowAccountDeleteWaring,
  selectShowResetWarning,
} from '@store/digi.me';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
})
export class DefaultComponent {
  readonly isResetting$ = this.store.select(selectIsResetLoading);
  readonly showResetWarning$ = this.store.select(selectShowResetWarning);

  readonly showAccountDeleteWaring$: Observable<boolean> = this.store.select(selectShowAccountDeleteWaring);
  readonly isAccountDeleting$ = this.store.select(selectIsAccountDeleting);
  readonly isAccountDeleted$ = this.store.select(selectIsAccountDeleted);
  readonly errorMessage$ = this.store.select(selectErrorMessage);
  readonly isSecurityPromptModalShown$ = this.store.select(selectShowSecurityPromptModal);
  readonly isNativePlatform = Capacitor.isNativePlatform();

  readonly showUseBiometricsModal$ = this.authenticationService.showUseBiometricsModal$;

  constructor(
    private readonly store: Store,
    private readonly menuService: MenuService, // Should be add because implementation requires it 🤔
    private readonly authenticationService: AuthenticationService,
  ) {}
}
