import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AccountsResponse } from '@digi.me/models';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';
import { AUTHORIZE_URL_API_ACTIONS, selectPushProviders } from '@store/digi.me';
import { filter, take } from 'rxjs';
import { UseShareProvidersComponent } from './share-providers.component';

@Component({
  standalone: true,
  selector: 'app-use-share-providers',
  imports: [UseShareProvidersComponent, CommonModule],
  template: `<app-use-share-providers-component
    [pushProvidersList]="pushProviders$ | async"
    (closeModal)="close()"
    (shareToProvider)="share($event)"></app-use-share-providers-component>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseShareProvidersContainerComponent {
  readonly pushProviders$ = this.store.select(selectPushProviders).pipe(
    filter((providers) => providers && providers.length > 0),
    take(1),
  );

  constructor(private readonly store: Store) {}

  close(): void {
    this.store.dispatch(APP_ACTIONS.showShareProvidersModal({ show: false }));
  }

  share(provider?: AccountsResponse): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({
        createNewUser: false,
        sourceType: 'push',
        sourceFetch: false,
        ...(provider && { serviceId: `${provider.sourceId}` }),
      }),
    );
  }
}
