/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { routes } from './app/app-routing.module';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { loadOrUseFallbackTranslations } from './locale/translations';

if (environment.production) {
  enableProdMode();
}

const mostPreferredTag = navigator.languages.find(
  (tag) => tag.toLocaleLowerCase().startsWith('nl') || tag.toLocaleLowerCase().startsWith('en'),
);
let fallbackLanguage = 'en-US';
if (mostPreferredTag?.startsWith('nl')) {
  fallbackLanguage = 'nl';
}

// The path has the highest order of determining the active language
let locale = window.location.pathname.split('/')[1].trim() || undefined;
if (locale === '') {
  locale = undefined;
}
// If it is not in the route, then pick the one from storage,
// otherwise use the fallback language,
//which is determined on whether nl is in the list of preferred languages and it is above any english language tags
const appLang = locale ?? localStorage.getItem('locale') ?? fallbackLanguage;
$localize.locale = appLang;

routes.unshift({ path: '', pathMatch: 'full', redirectTo: `/${$localize.locale}` });

loadOrUseFallbackTranslations(appLang);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    console.error(err);
  });
