import { environment } from '@environments/environment';
import { addIf } from '@globals';
import { sortMenu } from '@navigation/menu';
import { AddModalMenuItem } from '../../add-modal.menu';

export function getAddMeasurementsMenu(): AddModalMenuItem[] {
  return [
    ...addIf(environment.features.selfMeasurements.bodyWeight, {
      id: 'body-weights',
      header: $localize`:@@app.menu.bodyWeight:Body Weight`,
      icon: 'body-weights',
    } satisfies AddModalMenuItem),
    ...addIf(environment.features.selfMeasurements.bodyHeight, {
      id: 'body-heights',
      header: $localize`:@@app.menu.bodyHeight:Body Height`,
      icon: 'body-heights',
    } satisfies AddModalMenuItem),
  ].sort(sortMenu);
}
