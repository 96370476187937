import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import { AppleHealthUploadService } from '@core/services';
import {
  ButtonComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
  ModalStepperComponent,
} from '@layout';
import { MenuComponent } from '@navigation';
import { Store } from '@ngrx/store';
import { AUTHORIZE_URL_API_ACTIONS, IsLoadingAuthorizeUrl } from '@store/digi.me';
import { AddModalMenuItem } from '../../add-modal.menu';
import { AddModalService } from '../../services/add-modal.service';

@Component({
  selector: 'app-add-fitness-wellbeing-step',
  templateUrl: './add-fitness-wellbeing-step.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    MenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFitnessWellbeingStepComponent {
  @ViewChild(ModalStepperComponent) public stepper!: ModalStepperComponent;
  @Input() menu: AddModalMenuItem[] = [];

  @Input() isLoadingAuthorizeUrl: IsLoadingAuthorizeUrl = {
    isLoading: false,
    trigger: null,
  };

  private readonly service = inject(AddModalService);

  constructor(
    private readonly store: Store,
    readonly appleHealthUploadService: AppleHealthUploadService,
  ) {}

  select($event: AddModalMenuItem): void {
    switch ($event.id) {
      case 'fitbit':
      case 'withings':
        if ($event.sourceId) {
          this.addFitness($event.sourceId.toString());
        }
        break;
      case 'apple-health':
        this.appleHealthUploadService.addAppleHealth();
        break;
    }
  }

  addFitness(sourceId: string): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({
        createNewUser: false,
        sourceFetch: true,
        sourceType: 'pull',
        serviceId: sourceId,
      }),
    );
  }

  close() {
    this.service.close();
  }
}
