<app-menu-navbar></app-menu-navbar>
<router-outlet></router-outlet>

<app-digi-me-reset [resetting]="isResetting$ | async"
                   [showWarning]="showResetWarning$ | async"></app-digi-me-reset>

<app-account-deletion [showWarning]="showAccountDeleteWaring$ | async"
                      [showDeleting]="isAccountDeleting$ | async"
                      [showDeleted]="isAccountDeleted$ | async"></app-account-deletion>
<app-error-modal [errorMessage]="errorMessage$ | async"></app-error-modal>
<app-force-update-modal *ngIf="isNativePlatform"></app-force-update-modal>
<app-navigator-on-line></app-navigator-on-line>
<app-warning-update *ngIf="isNativePlatform"></app-warning-update>
<app-use-biometrics *ngIf="showUseBiometricsModal$ | async"></app-use-biometrics>
<app-use-security-prompt *ngIf="isSecurityPromptModalShown$ | async">></app-use-security-prompt>
