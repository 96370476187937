import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SubscriptionService } from '@core/services';
import { limitAndFormatNumber, LottieAnimationPaths, nameof, PaywallPlan, Urls } from '@globals';
import { Badge, ButtonComponent } from '@layout';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { PAYWALL_VIEW_ACTIONS } from '@store/app';
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { take } from 'rxjs';

export interface PaywallVM {
  purchasing: boolean;
  owned: boolean;
}

@Component({
  standalone: true,
  selector: 'app-paywall',
  templateUrl: './paywall.component.html',
  imports: [SharedModule, LottieComponent, ButtonComponent],
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  styles: [
    `
      .btn-close {
        background-color: white;
        border-radius: 50%;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallComponent implements AfterViewInit {
  @ViewChild('paywall', { static: false }) modal!: any;

  readonly vm = input.required<PaywallVM | null>();

  readonly modalShown = output<void>();

  managePurchase = output<{
    isProductOwned: boolean;
    plan: PaywallPlan;
  }>();

  readonly options: AnimationOptions = {
    path: LottieAnimationPaths.DOTS_LOADER,
    loop: true,
  };

  readonly form = new FormGroup({
    codeControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
  });

  plan = signal<PaywallPlan>('annual');
  selectAnnualPlan = computed(() => this.plan() === 'annual');
  selectMonthlyPlan = computed(() => this.plan() === 'month');

  modalRef: NgbModalRef | undefined;
  codeInput: string | number = '';
  showRedeemCode = false;
  badge: Badge = { label: $localize`:@@app.paywall.saving:Save 30%!`, color: '#d9b99b', textColor: 'black' };

  get monthlyPrice(): string | undefined {
    const offer = this.subscriptionService.monthlyOffer;
    if (!offer) {
      return;
    }

    const price = offer.pricingPhases[0].price;
    return `${price}/${$localize`:@@app.paywall.month:mo`}`;
  }

  get annualPrice(): string | undefined {
    const offer = this.subscriptionService.annualOffer;
    if (!offer) {
      return;
    }

    const price = offer.pricingPhases[0].price;
    return `${price}/${$localize`:@@app.paywall.year:yr`}`;
  }

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store,
    private readonly subscriptionService: SubscriptionService,
  ) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modal, {
      centered: true,
      backdrop: 'static',
      size: 'lg',
      windowClass: 'modal-xl',
      scrollable: true,
    });

    this.modalRef.shown.pipe(take(1)).subscribe(() => {
      this.modalShown.emit();
    });
  }

  get legalsLink(): string {
    return Urls.termsOfUse();
  }

  get helpLink(): string {
    return Urls.help();
  }

  get codeControl(): any {
    return this.form.get(nameof<PaywallComponent>('codeControl'));
  }

  closeModal(): void {
    this.modalRef!.close();

    this.store.dispatch(PAYWALL_VIEW_ACTIONS.closed());
  }

  togglePaywallPlan(plan: PaywallPlan): void {
    if (this.plan() === plan) {
      return;
    }

    this.plan.set(plan);
  }

  continue(plan: PaywallPlan, isProductOwned: boolean): void {
    if (isProductOwned) {
      this.closeModal();
    }

    this.managePurchase.emit({ isProductOwned, plan });
  }

  sanitizeCodeInput(target: EventTarget | null): void {
    const input = target as HTMLInputElement;
    const value = limitAndFormatNumber(input.value, 999, 0);
    this.codeControl!.setValue(value);
    this.codeInput = value;
  }

  checkCode(): boolean {
    return this.codeInput != 1;
  }

  toggleRedeem(): void {
    this.showRedeemCode = !this.showRedeemCode;
  }

  restore(): void {
    this.subscriptionService.restore();
  }
}
