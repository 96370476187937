import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { DELETE_UI_ACTIONS, selectUserReauth } from '@store/digi.me';
import { USER_ACTIONS } from '@store/user';

@Component({
  standalone: true,
  selector: 'app-use-security-prompt',
  templateUrl: './security-prompt.component.html',
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseSecurityPromptComponent implements AfterViewInit {
  @ViewChild('useSecurityPromptModal', { static: false }) modal!: any;

  modalRef: NgbModalRef | undefined;

  readonly selectUserReauth$ = this.store.select(selectUserReauth);
  readonly noAccessDesc =
    $localize`:@@app.securityPrompt.noaccess.description:Security Prompt No Access markdown`.replaceAll('\\n', '\n');

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store,
  ) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  reauth(reauthUrl: string | undefined): void {
    if (reauthUrl) {
      window.location.href = reauthUrl;
    }
  }

  async deleteAccount(): Promise<void> {
    this.store.dispatch(DELETE_UI_ACTIONS.deleteWarningFromSecurityPrompt());
  }

  async logout(): Promise<void> {
    this.modalRef!.close();
    this.store.dispatch(USER_ACTIONS.signOutFromReauth());
  }
}
