import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  ButtonComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
  ModalStepService,
} from '@layout';
import { MenuComponent } from '@navigation';
import { AddModalMenuItem } from '../../add-modal.menu';
import { getNextStepFor } from '../../services/add-modal-step.service';
import { getAddVitalSignsMenu } from './add-vital-signs-step.menu';

@Component({
  selector: 'app-add-vital-signs-step',
  templateUrl: './add-vital-signs-step.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    MenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddVitalSignsStepComponent {
  nextStepComponentType: any;

  readonly menu: AddModalMenuItem[] = getAddVitalSignsMenu();
  private readonly service = inject(ModalStepService);

  select($event: AddModalMenuItem): void {
    const nextStep = getNextStepFor($event);
    if (nextStep) {
      this.nextStepComponentType = nextStep;
    }
    this.service.next();
  }

  close() {
    this.service.close();
  }
}
