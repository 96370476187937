import { AddModalService } from '@add-modal/services';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType } from '@digi.me/models';
import { ObservationStorage, addIf, addIfObject, addIfProperty } from '@globals';
import { Coding } from '@hl7fhir';
import { CodeSystems } from '@hl7fhir/codesystems';
import { ValueSets } from '@hl7fhir/value-sets';
import {
  HOUDING_CODELIJST,
  MANCHET_TYPE_CODELIJST,
  MEETLOCATIE_CODELIJST,
  MEETMETHODE_CODELIJST,
} from '@hl7nl-fhir/value-sets';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserAD } from '../digi.me/models/ad-user.model';
import { BloodPressureEntryComponent } from './blood-pressure-entry/blood-pressure-entry.component';
import { BLOOD_PRESSURE_TEMPLATE } from './blood-pressure-entry/blood-pressure.template';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-blood-pressure',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    BloodPressureEntryComponent,
  ],
  templateUrl: './self-measurements-modal-blood-pressure.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalBloodPressureComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    systolicControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    diastolicControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    positionControl: new FormControl(''),
    cuffSizeControl: new FormControl(''),
    locationControl: new FormControl(''),
    methodControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly houdingCodelijst = HOUDING_CODELIJST;
  readonly manchetTypeCodelijst = MANCHET_TYPE_CODELIJST;
  readonly meetlocatieCodelijst = MEETLOCATIE_CODELIJST;
  readonly meetmethodeCodelijst = MEETMETHODE_CODELIJST;
  readonly translatedHoudingCodelijst: Coding[];
  readonly translatedManchetTypeCodelijst: Coding[];
  readonly translatedMeetlocatieCodelijst: Coding[];
  readonly translatedMeetmethodeCodelijst: Coding[];

  readonly user$ = this.store.select(selectUser);

  private service = inject(AddModalService);

  constructor(private readonly store: Store) {
    this.translatedHoudingCodelijst = BloodPressureEntryComponent.houdingCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [HOUDING_CODELIJST]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedManchetTypeCodelijst = BloodPressureEntryComponent.mancheTypeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MANCHET_TYPE_CODELIJST]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedMeetlocatieCodelijst = BloodPressureEntryComponent.meetlocatieCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MEETLOCATIE_CODELIJST]);
      return { ...coding, display: display.code } as Coding;
    });
    this.translatedMeetmethodeCodelijst = BloodPressureEntryComponent.meetmethodeCodelijstCodings.map((coding) => {
      const display = ValueSets.getDisplay(coding, [MEETMETHODE_CODELIJST]);
      return { ...coding, display: display.code } as Coding;
    });
  }

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (!this.form.value['systolicControl'] || !this.form.value['diastolicControl']) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const systolic = this.form.value['systolicControl'];
    const diastolic = this.form.value['diastolicControl'];
    const position = this.form.value['positionControl'] ?? undefined;
    const cuffSize = this.form.value['cuffSizeControl'] ?? undefined;
    const location = this.form.value['locationControl'] ?? undefined;
    const method = this.form.value['methodControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...BLOOD_PRESSURE_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...BLOOD_PRESSURE_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...BLOOD_PRESSURE_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      component: [
        {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8480-6',
                display: 'Systolic blood pressure',
              },
            ],
          },
          valueQuantity: {
            value: parseInt(systolic!),
            unit: 'mmHg',
            system: 'http://unitsofmeasure.org',
            code: 'mm[Hg]',
          },
        },
        {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8462-4',
                display: 'Diastolic blood pressure',
              },
            ],
          },
          valueQuantity: {
            value: parseInt(diastolic!),
            unit: 'mmHg',
            system: 'http://unitsofmeasure.org',
            code: 'mm[Hg]',
          },
        },
        ...addIf(!!cuffSize, {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8358-4',
                display: 'Blood pressure cuff size',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: cuffSize,
                display: this.translatedManchetTypeCodelijst.find((coding) => coding.code === cuffSize)?.display,
              },
            ],
          },
        }),
        ...addIf(!!position, {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '8361-8',
                display: 'Body position with respect to gravity',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: position,
                display: this.translatedHoudingCodelijst.find((coding) => coding.code === position)?.display,
              },
            ],
          },
        }),
      ],
      ...addIfObject(!!location, {
        bodySite: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: location,
              display: this.translatedMeetlocatieCodelijst.find((coding) => coding.code === location)?.display,
            },
          ],
        },
      }),
      ...addIfObject(!!method, {
        method: {
          coding: [
            {
              system: 'http://snomed.info/sct',
              code: method,
              display: this.translatedMeetmethodeCodelijst.find((coding) => coding.code === method)?.display,
            },
          ],
        },
      }),
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: ObservationStorage.FOLDERS.SELF_MEASUREMENTS,
        measurementType: MeasurementType.bloodPressure,
      }),
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      systolicControl: '',
      diastolicControl: '',
      positionControl: '',
      cuffSizeControl: '',
      locationControl: '',
      additionalCommentsControl: '',
    });
  }
}
