<ng-template #useSecurityPromptModal
             let-modal
             *ngrxLet="selectUserReauth$ as selectUserReauth">
  <div class="modal-header d-flex flex-column align-items-start">
    <div class="d-flex flex-row w-100 justify-content-between">
      <svg class="reauth-noaccess-icon">
        <use *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl !== ''"
             xlink:href="./assets/images/assets.svg#reauth"></use>
        <use *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl === ''"
             xlink:href="./assets/images/assets.svg#noaccess"></use>
      </svg>
    </div>

    <h5 *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl !== ''"
        class="modal-title font-weight-bold"
        id="modal-basic-title"
        i18n="@@app.securityPrompt.unlock.title">
      Unlock your Health Vault
    </h5>
    <h5 *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl === ''"
        class="modal-title font-weight-bold"
        id="modal-basic-title"
        i18n="@@app.securityPrompt.noaccess.title">
      Unable to access your health vault
    </h5>
  </div>
  <div class="modal-body">
    <p *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl !== ''"
       style="white-space: pre-line;"
       i18n="@@app.securityPrompt.unlock.description">
      After a period of inactivity your health vault requires that you manually unlock it. To do this you may need to
      log in to one of your previously linked sources. This is to protect your important medical records.
    </p>
    <p *ngIf="selectUserReauth?.shouldReauth && selectUserReauth?.reauthUrl === ''"
       style="white-space: pre-line;">
      {{noAccessDesc}}
    </p>
  </div>
  <div class="modal-footer d-flex flex-column">
    <app-button *ngIf="selectUserReauth !== null && selectUserReauth?.reauthUrl !== ''"
                type="primary"
                use="block"
                class="w-100"
                label="Unlock Health Vault"
                i18n-label="@@app.securityPrompt.btn.unlock"
                (clicked)="reauth(selectUserReauth?.reauthUrl)"></app-button>
    <app-button type="secondary"
                use="block"
                class="w-100"
                label="Delete Account"
                i18n-label="@@app.securityPrompt.btn.delete"
                (clicked)="deleteAccount()"></app-button>
    <app-button type="secondary"
                use="block"
                class="w-100"
                label="Log Out"
                i18n-label="@@app.securityPrompt.btn.logout"
                (clicked)="logout()"></app-button>
  </div>
</ng-template>
