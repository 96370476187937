<app-modal-step>
  <app-modal-step-header>
    <h2 class="fw-bold"
        i18n="@@app.addModal.readingTitle">
      Add vital reading?
    </h2>
  </app-modal-step-header>
  <app-modal-step-body>
    <app-menu [menu]="menu"
              (selected)="select($event)"
              [rightArrow]="true"></app-menu>
  </app-modal-step-body>
  <app-modal-step-footer>
    <app-button use="block"
                type="secondary"
                label="Cancel"
                i18n-label="@@app.modal.button.cancel"
                (clicked)="close()">
    </app-button>
  </app-modal-step-footer>
</app-modal-step>

<ng-container *ngComponentOutlet="nextStepComponentType"></ng-container>
