<div class="toast-wrapper">
  <div class="toast-container">
    <ngb-toast *ngFor="let toast of toasts$ | async"
               [class]="getClassName(toast.typeMessage)"
               [delay]="5000"
               (click)="removeToast(toast.message)"
               (hidden)="removeToast(toast.message)">
      <div class="toast-content">
        <mat-icon class="toast-icon">check_circle</mat-icon>
        <span class="toast-message">{{ toast.message }}</span>
      </div>
    </ngb-toast>
  </div>
</div>
