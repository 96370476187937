import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { DigiMeModule } from '@digi.me';
import { wrapTitle } from '@language';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';
import { NOTIFICATIONS_API_ACTIONS, selectIsClaimLoading } from '@store/digi.me';
import { USER_ACTIONS } from '@store/user';
import { Observable } from 'rxjs';
import { OnboardOrLogInComponent } from './onboard-or-log-in.component';

@Component({
  standalone: true,
  selector: 'app-onboard-or-log-in-container',
  imports: [CommonModule, OnboardOrLogInComponent, DigiMeModule],
  template: `<app-onboard-or-log-in
      *ngIf="(loading$ | async) === false"
      (onboard)="onboard()"
      (login)="login()"></app-onboard-or-log-in>
    <app-digi-me-loading [loading]="loading$ | async"></app-digi-me-loading>`,
})
export class OnboardOrLogInContainerComponent implements OnInit {
  readonly loading$: Observable<boolean> = this.store.select(selectIsClaimLoading);
  readonly platform = Capacitor.getPlatform();

  constructor(
    private readonly title: Title,
    private readonly store: Store,
    private readonly router: Router,
  ) {}

  login(): void {
    this.store.dispatch(APP_ACTIONS.authenticationStarted({ prompt: 'login' }));
  }

  onboard() {
    this.store.dispatch(USER_ACTIONS.signUpWithoutLibClaim());
  }

  ngOnInit() {
    this.title.setTitle(wrapTitle($localize`:@@app.OnboardOrLogIn:Onboard or Log in`));
    this.store.dispatch(NOTIFICATIONS_API_ACTIONS.notificationRequested());
  }
}
