import { CommonModule } from '@angular/common';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Capacitor } from '@capacitor/core';
import { Notification } from '@digi.me/models';
import { openEmbeddedBrowser } from '@globals';
import { toAzureAdB2c } from '@language';
import { Store } from '@ngrx/store';
import {
  NOTIFICATIONS_API_ACTIONS,
  selectNotification,
  selectShowNotifications,
  selectUserAd,
  UPDATE_AD_USER_API_ACTIONS,
} from '@store/digi.me';
import { filter, take } from 'rxjs';
import { NotificationsBannerComponent } from './notifications-banner.component';

@Component({
  selector: 'app-notifications-banner-container',
  standalone: true,
  imports: [CommonModule, NotificationsBannerComponent],
  template: `
    <app-notifications-banner
      *ngIf="showNotification$ | async"
      [notification]="notification"
      (dismissBanner)="dismissBanner()"
      (openLink)="openLink($event)">
    </app-notifications-banner>
  `,
})
export class NotificationsBannerContainerComponent implements OnInit {
  notification: Notification | undefined;
  readonly notification$ = this.store.select(selectNotification);
  readonly showNotification$ = this.store.select(selectShowNotifications);

  ngOnInit() {
    this.notification$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((notification) => !!notification),
      )
      .subscribe((notification) => {
        this.notification = notification;
      });
  }

  readonly userAd$ = this.store.select(selectUserAd);

  locale = toAzureAdB2c($localize.locale);

  constructor(
    private store: Store,
    private destroyRef: DestroyRef,
  ) {}

  async openLink(actionUrl: string) {
    if (actionUrl) {
      if (Capacitor.isNativePlatform()) {
        await openEmbeddedBrowser(actionUrl);
      } else {
        window.open(actionUrl, '_blank');
      }
    }
  }

  dismissBanner() {
    if (this.notification?.name === 'privacy-warning' && this.notification.targetPrivacyVersion) {
      this.updateUser(this.notification.targetPrivacyVersion);
    }
    this.store.dispatch(
      NOTIFICATIONS_API_ACTIONS.notificationDismissed({
        notificationReceipt: {
          name: this.notification?.name,
          description: this.notification?.description,
          id: this.notification?.id,
          timestamp: new Date().toISOString(),
        },
      }),
    );
  }

  updateUser(targetPrivacyVersion: string) {
    this.userAd$.pipe(take(1)).subscribe((userAd) => {
      if (userAd) {
        const updatedUserAd = { ...userAd, privacyVersionUser: targetPrivacyVersion };
        this.store.dispatch(UPDATE_AD_USER_API_ACTIONS.updateADUserRequested({ user: updatedUserAd }));
      }
    });
  }
}
