import { Component, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import {
  AppListenersService,
  AuthenticationService,
  NavigatorOnLineService,
  PreviousRouteService,
} from '@core/services';
import { configId, environment } from '@environments/environment';
import { FreshdeskService } from '@freshdesk';
import { MixpanelService } from '@logging';
import { findInMenuItems, getMenuConfig } from '@navigation/menu';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Store } from '@ngrx/store';
import { APP_ACTIONS, IDLE_ACTIONS, PAGE_ACTIONS } from '@store/app';
import { FORCE_UPDATE_API_ACTIONS } from '@store/digi.me';
import { EventTypes, LoginResponse, OidcSecurityService, PublicEventsService } from 'angular-auth-oidc-client';
import { Observable, filter, map } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly oidcEventService: PublicEventsService,
    readonly router: Router,

    private previousRouteService: PreviousRouteService,
    private readonly mixpanelService: MixpanelService,
    private readonly freshdeskService: FreshdeskService,
    private readonly navigatorService: NavigatorOnLineService,
    private readonly idle: Idle,
    private readonly authenticationService: AuthenticationService,
    private readonly appListenersService: AppListenersService,
  ) {
    this.initializeApp();
    this.setPageHeader();

    // Configure idle detection
    // set idle parameters
    this.idle.setIdle(60); // how long can they be inactive before considered idle, in seconds
    this.idle.setTimeout(environment.sessionTimeout); // how long can they be idle before considered timed out, in seconds
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.start());
    });

    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.end());
    });

    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      this.store.dispatch(IDLE_ACTIONS.timeout());
    });
  }

  ngOnInit(): void {
    this.store.dispatch(FORCE_UPDATE_API_ACTIONS.forceUpdateRequested());

    this.oidcEventService
      .registerForEvents()
      .pipe(filter((notification) => notification.type === EventTypes.SilentRenewFailed))
      .subscribe(() => {
        this.store.dispatch(APP_ACTIONS.authenticationFailed());
      });

    // When we come back in a different tab the return to process will be set
    // here we will wait for the authorization to complete and then try
    // to reprocess the return
    this.oidcSecurityService.isAuthenticated$
      .pipe(map((result) => result.allConfigsAuthenticated.some((x) => x.configId === configId && x.isAuthenticated)))
      .pipe(filter((isAuthenticated) => isAuthenticated))
      .subscribe(() => {
        const returnToProcess = sessionStorage.getItem('returnToProcess');
        if (returnToProcess) {
          sessionStorage.removeItem('returnToProcess');
          setTimeout(() => {
            this.router.navigateByUrl(`/${$localize.locale}/return${returnToProcess}`);
          }, 10);
        }
      });

    if (!Capacitor.isNativePlatform()) {
      this.idle.watch();
    }
  }

  private initializeApp(): void {
    this.authenticationService.authenticate().subscribe({
      next: async (response: LoginResponse) => {
        const isReturn = window.location.href.includes('/return?');
        const countAsVisit = !window.location.href.includes('/callback') && !isReturn;
        if (countAsVisit) {
          this.store.dispatch(APP_ACTIONS.userVisit());
        }

        if (response.isAuthenticated) {
          this.store.dispatch(
            APP_ACTIONS.authenticated({ isReturn, skipHomepage: localStorage.getItem('skipHomepage') === 'true' }),
          );
        } else {
          this.store.dispatch(APP_ACTIONS.unauthenticated());
        }
      },
      error: (error) => {
        this.store.dispatch(APP_ACTIONS.authenticationError({ error: error }));
        this.router.navigate([`${$localize.locale}`, 'onboard-or-log-in']);
      },
    });

    this.appListenersService.init();
    this.previousRouteService.startHistoryTracking();
    this.mixpanelService.init();
    this.navigatorService.init();
    this.freshdeskService.loadWidget();
  }

  private setPageHeader(): void {
    const navigationEnd: Observable<NavigationEnd> = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
    ) as Observable<NavigationEnd>;
    const url$: Observable<string> = navigationEnd.pipe(map((event) => event.urlAfterRedirects));

    url$.pipe(takeUntilDestroyed()).subscribe((url: string) => {
      const menuItem = findInMenuItems(getMenuConfig().menuItems, url);

      this.store.dispatch(PAGE_ACTIONS.setPageHeader({ header: menuItem?.header ?? '' }));
    });
  }
}
