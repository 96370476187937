import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { AuthenticationService } from '@core/services';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-use-biometrics',
  templateUrl: './use-biometrics.component.html',
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseBiometricsComponent implements AfterViewInit {
  @ViewChild('useBiometricsModal', { static: false }) modal!: any;

  modalRef: NgbModalRef | undefined;

  constructor(
    private readonly modalService: NgbModal,
    private readonly authenticationService: AuthenticationService,
  ) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  async handleBiometricsSettings(useBiometrics: boolean): Promise<void> {
    this.modalRef!.close();

    await this.authenticationService.setUseBiometrics(useBiometrics);
    await this.authenticationService.setHideUseBiometricsModal(true);
  }
}
