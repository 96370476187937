<app-menu-navbar></app-menu-navbar>
<router-outlet></router-outlet>

<app-digi-me-reset [resetting]="isResetting$ | async"
                   [showWarning]="showResetWarning$ | async"></app-digi-me-reset>

<app-account-deletion [showWarning]="showAccountDeleteWaring$ | async"
                      [showDeleting]="isAccountDeleting$ | async"
                      [showDeleted]="isAccountDeleted$ | async"></app-account-deletion>
<app-error-modal [errorMessage]="errorMessage$ | async"></app-error-modal>
<app-notifications-modal *ngIf="isNativePlatform"></app-notifications-modal>
<app-demo-mode-modal *ngIf="showDemoModeModal$ | async"></app-demo-mode-modal>
<app-paywall-container *ngIf="showPaywall$ | async"></app-paywall-container>
<app-navigator-on-line></app-navigator-on-line>
<app-notifications-banner-container></app-notifications-banner-container>
<app-use-biometrics *ngIf="showUseBiometricsModal$ | async"></app-use-biometrics>
<app-use-security-prompt *ngIf="isSecurityPromptModalShown$ | async">></app-use-security-prompt>
<app-use-share-providers *ngIf="isShareProvidersModalShown$ | async"></app-use-share-providers>