import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Notification } from '@digi.me/models';
import { toAzureAdB2c } from '@language';
import { marked } from 'marked';

@Component({
  selector: 'app-notifications-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './notifications-banner.component.html',
  styleUrl: './notifications-banner.component.scss',
})
export class NotificationsBannerComponent implements OnInit {
  locale = toAzureAdB2c($localize.locale);
  isHover = false;
  parsedMessageBody = '';

  @Input() notification?: Notification;

  @Output() openLink = new EventEmitter<string>();
  @Output() dismissBanner = new EventEmitter<void>();

  ngOnInit() {
    if (this.notification?.messageBody) {
      this.parseMarkdown(this.notification.messageBody?.[this.locale]).then((parsed) => {
        this.parsedMessageBody = parsed;
      });
    }
  }

  onOpenLink(actionUrl: string | undefined) {
    if (actionUrl) {
      this.openLink.emit(actionUrl);
    }
  }

  onDismissBanner() {
    this.dismissBanner.emit();
  }

  async parseMarkdown(messageBody: string): Promise<string> {
    const renderer = new marked.Renderer();
    renderer.paragraph = (text: string) => text;
    return await marked.parse(messageBody, { renderer });
  }

  getSeparatorColor(bannerEmphasis: string): string {
    return bannerEmphasis === 'high' ? 'white' : 'black';
  }

  handleKeyDown(event: KeyboardEvent, type: string, actionUrl: string | undefined) {
    if (event.key === 'Enter') {
      if (type === 'dismiss') {
        this.onDismissBanner();
      } else {
        this.onOpenLink(actionUrl);
      }
    }
  }
}
