import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { selectToast, TOAST_ACTIONS, ToastState } from '@store/app';
import { Observable } from 'rxjs';

@Component({
  standalone: true,
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  imports: [SharedModule, NgbToastModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToastComponent {
  toasts$: Observable<ToastState[]>;

  constructor(private store: Store) {
    this.toasts$ = this.store.select(selectToast);
  }

  removeToast(message: string): void {
    this.store.dispatch(TOAST_ACTIONS.hideToast({ message }));
  }

  getClassName(typeMessage: string): string {
    switch (typeMessage) {
      case 'error':
        return 'bg-danger text-light';
      case 'info':
        return 'toast-custom';
      default:
        return 'toast-custom';
    }
  }
}
