import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { CANCEL_SIGN_UP_API_ACTIONS, SIGN_UP_API_ACTIONS, selectLibrary } from '@store/digi.me';
import { AccountCreateComponent } from './account-create.component';

@Component({
  standalone: true,
  selector: 'app-account-create-container',
  imports: [AccountCreateComponent, CommonModule],
  template: `<app-account-create
    (create)="create($event)"
    [library]="library$ | async"
    (cancel)="cancel()"></app-account-create>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCreateContainerComponent {
  readonly library$ = this.store.select(selectLibrary);

  constructor(private readonly store: Store) {}

  create(library: string) {
    this.store.dispatch(SIGN_UP_API_ACTIONS.signUpStarted({ library: library }));
  }

  cancel() {
    this.store.dispatch(CANCEL_SIGN_UP_API_ACTIONS.cancelSignUpStarted());
  }
}
