<ng-template #useBiometricsModal
             let-modal>
  <div class="modal-header d-flex flex-column align-items-start">
    <div class="d-flex flex-row w-100 justify-content-between">
      <svg class="fingerprint-icon"
           aria-hidden="true">
        <use xlink:href="./assets/images/assets.svg#fingerprint"></use>
      </svg>
    </div>

    <h5 class="modal-title font-weight-bold"
        id="modal-basic-title"
        i18n="@@app.dialog.biometrics.title">Enable Quick & Secure Login</h5>
  </div>
  <div class="modal-body">
    <p i18n="@@app.dialog.biometrics.description">
      Log in instantly using your face or fingerprint. Biometrics are faster, safer, and more convenient than entering
      your email, password, and waiting for a verification code every time.</p>
  </div>
  <div class="modal-footer d-flex flex-column">
    <app-button type="primary"
                use="block"
                class="w-100"
                label="Enable biometrics"
                i18n-label="@@app.dialog.biometrics.btn.yes"
                (clicked)="handleBiometricsSettings(true)"></app-button>
    <app-button type="secondary"
                use="block"
                class="w-100"
                label="Keep logging in manually"
                i18n-label="@@app.dialog.biometrics.btn.no"
                (clicked)="handleBiometricsSettings(false)"></app-button>
  </div>
</ng-template>