<app-modal-stepper #stepper>
  <app-modal-step>
    <app-modal-step-header>
      <h2 class="fw-bold"
          i18n="@@app.addModal.addData">
        Add data
      </h2>
    </app-modal-step-header>
    <app-modal-step-body>
      <app-menu [menu]="contextAwareMenu"
                (selected)="selected($event)"
                [rightArrow]="true"></app-menu>
      <app-menu [menu]="selfMeasurementsAndVitalSignsMenu"
                (selected)="selected($event)"
                [rightArrow]="true"></app-menu>
      <h2 class="fw-bold"
          i18n="@@app.addModal.linkData">
        Link data
      </h2>
      <app-menu [menu]="sourcesAndFitnessMenu"
                (selected)="selected($event)"
                [rightArrow]="true"></app-menu>
    </app-modal-step-body>
    <app-modal-step-footer>
      <app-button use="block"
                  type="secondary"
                  label="Cancel"
                  i18n-label="@@app.modal.button.cancel"
                  (clicked)="stepper.close('Cancel click')">
      </app-button>
    </app-modal-step-footer>
  </app-modal-step>
  <ng-container *ngComponentOutlet="nextStepComponentType"></ng-container>
</app-modal-stepper>
