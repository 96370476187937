import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { USER_ACTIONS } from '@store/user';

@Injectable({ providedIn: 'root' })
export class DeepLinkCreateAccountGuard {
  constructor(private readonly store: Store) {}

  canActivate() {
    this.store.dispatch(USER_ACTIONS.signUpWithoutLibClaim());
    return true;
  }
}
