import { assertUnion, OrganizationType } from '@globals';
import { AddModalMenuItem } from '../../add-modal.menu';

export function getAddMedicalRecordsMenu(): AddModalMenuItem[] {
  return [
    {
      id: 'gp-record',
      header: $localize`:@@app.addModal.recordsOptionGP:GP Record`,
      icon: 'health-record',
      organizationType: assertUnion<OrganizationType>('general-practitioner'),
    },
    {
      id: 'hospital',
      header: $localize`:@@app.addModal.recordsOptionHospital:Hospital record`,
      icon: 'sources',
      organizationType: assertUnion<OrganizationType>('hospital'),
    },
    {
      id: 'vaccinations',
      header: $localize`:@@app.addModal.recordsOptionVaccine:Vaccine record`,
      icon: 'vaccinations',
      sourceId: '28846',
    },
    {
      id: 'not-sure',
      header: $localize`:@@app.addModal.recordsOptionNotSure:Not sure`,
      icon: 'my-details',
    },
  ];
}
