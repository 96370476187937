import { registerLocaleData } from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import { loadTranslations } from '@angular/localize';
import { environment } from '@environments/environment';
import messagesEnUS from '../locale/messages.en_US.json';
import messagesNl from '../locale/messages.nl.json';

export function loadOrUseFallbackTranslations(locale: string) {
  try {
    const months = localeNl[5];
    if (months && Array.isArray(months)) {
      months[1] = ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'];
    }
  } catch {
    // Don't let this break the app, it is only removing the . from the months in Dutch.
    // But do break when we are running in non-production
    if (environment.production !== false) {
      // eslint-disable-next-line no-debugger
      debugger;
    }
  }

  registerLocaleData(localeNl);
  // Get translations from the compiled app
  switch (locale) {
    case 'en-US':
      loadTranslations(messagesEnUS.translations);
      break;
    case 'nl':
      registerLocaleData(localeNl);
      loadTranslations(messagesNl.translations);
      break;
    default:
      registerLocaleData(localeNl);
      loadTranslations(messagesNl.translations);
      break;
  }
}
