<div class="d-flex"
     [ngClass]="
   {
     'navbar-offline': (online$ | async) === false,
     'navbar-warning': (showNotificationBanner$ | async) === true
   }">
  <div class="side d-none d-lg-block overflow-auto px-3 px-md-4">
    <app-side-menu-container></app-side-menu-container>
  </div>
  <div class="main d-flex flex-grow-1 w-100">
    <div class="main-content flex-grow-1 ps-3 ps-lg-0 pe-3 pe-md-4">
      @if ((mustCreateAccount$ | async) === true) {
      <app-account-create-container></app-account-create-container>
      } @else {
      <app-middle-menu-container></app-middle-menu-container>
      <router-outlet data-cy="main-router-outlet"></router-outlet>
      }
    </div>
  </div>
</div>
