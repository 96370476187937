import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FooterComponent } from '@core';
import { ButtonComponent, HeaderComponent, MedMijTrustedComponent, WdxFooterComponent } from '@layout';
import { BackArrowComponent } from '@navigation';
import { MarkdownComponent, provideMarkdown } from 'ngx-markdown';

@Component({
  standalone: true,
  selector: 'app-getting-started-consent',
  templateUrl: 'getting-started-consent.component.html',
  imports: [
    MedMijTrustedComponent,
    FooterComponent,
    ButtonComponent,
    WdxFooterComponent,
    HeaderComponent,
    BackArrowComponent,
    MarkdownComponent,
  ],
  providers: [provideMarkdown()],
})
export class GettingStartedConsentComponent {
  @Output() consent = new EventEmitter();
  @Output() skipLinking = new EventEmitter();

  @Input() isLoadingAuthorizeUrl = false;

  digiMeHref = $localize.locale === 'nl' ? 'https://digi.me/nl/how' : 'https://digi.me/how';

  data =
    $localize`:@@app.signup.consentDetails:1. The MedMij rules apply to the sign up process. Only Dutch residents are able
  to have a PGO.
  2. First you will be asked to select your participating health provider.
  3. Then you will be
  asked to establish your eligibility through your DigiD.
  4. After your eligibility is confirmed, digi.me downloads
  your records from the health provider you selected into a secure, encrypted data vault.
  5. Then digi.me will ask
  you to complete the PGO registration process by asking for your email address and verifying it using two-factor
  authentication.
  6. Once this is done digi.me links your account to the health records you selected.

  If you need any further information please visit our [Help Centre](https://digi.me/help) or our [Getting Started Guide](https://digi.me/how).

  By continuing, you agree that you have read, understood and are bound by our [Terms and Conditions](https://digi.me/legal/terms) and our [Privacy Policy](https://digi.me/legal/privacy).`.replaceAll(
      '\\n',
      '\n',
    );

  skipLinkingSourceText: string = $localize`:@@app.signup.consentSkipDescription:You can create an account without
  linking to any healthcare providers first, useful if you only want to add your own self measurements`;
}
