import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { selectIsAuthorizeUrlLoading } from '@store/digi.me';
import { Observable } from 'rxjs';
import { AddModalMenuItem, markTriggeringMenuItem } from '../../add-modal.menu';
import { AddMedicalRecordsStepComponent } from './add-medical-records-step.component';
import { getAddMedicalRecordsMenu } from './add-medical-records-step.menu';

@Component({
  standalone: true,
  imports: [AddMedicalRecordsStepComponent, CommonModule],
  selector: 'app-add-medical-records-step-container',
  template: `<app-add-medical-records-step
    [menu]="(medicalRecordsMenu$ | async) ?? []"></app-add-medical-records-step> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMedicalRecordsStepContainerComponent {
  medicalRecordsMenu$: Observable<AddModalMenuItem[]> = this.store.select(selectMedicalRecordsWithLoading);

  constructor(private readonly store: Store) {}
}

/**
 * Selector that selects the add medical source menus together with the working state,
 * meaning that that menu item triggered the authorize url request.
 */
const selectMedicalRecordsWithLoading = createSelector(selectIsAuthorizeUrlLoading, (authorizeUrlRequest) => {
  const menu = getAddMedicalRecordsMenu();
  markTriggeringMenuItem(menu, authorizeUrlRequest);
  return menu;
});
