<div *ngIf="notification"
     [ngClass]="notification.emphasis"
     class="warning-update"
     (click)="onOpenLink(notification.actionUrl)"
     (keydown)="handleKeyDown($event, 'open', notification.actionUrl)"
     tabindex="0">
  <div class="warning-content d-flex align-items-center"
       (mouseover)="notification.actionUrl ? isHover = true : null"
       (mouseout)="isHover = false"
       (focus)="notification.actionUrl ? isHover = true : null"
       (blur)="isHover = false">
    <span class="me-auto d-flex align-items-center ms-4"
          [ngStyle]="{ textDecoration: isHover ? 'underline' : 'none' }"
          [innerHTML]="parsedMessageBody">
    </span>

    <span *ngIf="notification.dismissible"
          class="separator me-4"
          [ngStyle]="{ backgroundColor: getSeparatorColor(notification.emphasis) }">
    </span>

    <span *ngIf="notification.dismissible"
          (click)="onDismissBanner()"
          (keydown)="handleKeyDown($event, 'dismiss', notification.actionUrl)"
          class="dismiss-text me-4"
          tabindex="0">
      {{ notification.messageButton?.[this.locale] || 'Dismiss' }}
    </span>
  </div>
</div>