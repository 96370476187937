import { Component } from '@angular/core';
import { ButtonComponent } from '@layout';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delete-account-completed',
  standalone: true,
  imports: [ButtonComponent],
  template: `
    <div
      data-cy="account-deletion-completed-modal"
      class="modal-header border-0 p-4">
      <svg
        class="error-icon"
        aria-hidden>
        <use xlink:href="./assets/images/assets.svg#error-pink"></use>
      </svg>
    </div>
    <div class="modal-body border-0 px-4 py-0">
      <h4
        class="modal-title fw-bolder text-center"
        id="modal-basic-title"
        i18n="@@app.delete-account.deleted">
        Your account has been deleted.
      </h4>
    </div>
    <div class="modal-footer border-0 justify-content-center p-4">
      <app-button
        type="primary"
        i18n-label="@@app.delete-account.close"
        label="Close"
        (clicked)="activeModal.close('Close')">
      </app-button>
    </div>
  `,
})
export class DeleteAccountCompletedModalComponent {
  constructor(public activeModal: NgbActiveModal) {}
}
