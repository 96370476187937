<div class="d-flex"
     [ngClass]="
      {
      'navbar-offline': (online$ | async) === false,
      'navbar-warning': (showNotificationBanner$ | async) === true
      }">
  <div class="main d-flex flex-grow-1 w-100">
    <div class="main-content flex-grow-1 px-3 px-md-4">
      @if((mustCreateAccount$ | async) === true) {
      <app-account-create-container></app-account-create-container>
      } @else {
      <router-outlet data-cy="main-router-outlet"></router-outlet>
      }
    </div>
  </div>
</div>
