import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { User } from '@digi.me/models';
import { FreshdeskService } from '@freshdesk';
import { ErrorModel, getErrorModel } from '@globals';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { ERROR_UI_ACTIONS } from '@store/app';
import { selectUser } from '@store/digi.me';

@Component({
  standalone: true,
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  imports: [SharedModule, NgbModalModule, ButtonComponent, RouterModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorModalComponent implements OnChanges {
  @Input() errorMessage: any;

  @ViewChild('errorModal', { static: false }) modal: any;

  error: ErrorModel | undefined = undefined;

  readonly user$ = this.store.select(selectUser);

  constructor(
    private readonly modalService: NgbModal,
    private readonly store: Store,
    private readonly freshdeskWidgetService: FreshdeskService,
    private readonly router: Router,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    const errorMessage = changes['errorMessage'].currentValue;

    // Show fallback/default error message when there is no specific
    if (errorMessage?.data) {
      this.error = getErrorModel(errorMessage.data);
      setTimeout(() => {
        this.open();
      }, 500);
    }
  }

  contactSupport(user: User | null): void {
    const userId = user?.id;

    this.freshdeskWidgetService.freshworksWidget('prefill', 'ticketForm', {
      subject: $localize`:@@app.feedback.widget.reporterror:Error Report` + `${userId ? ` (${userId})` : ''}`,
    });
    this.freshdeskWidgetService.freshworksWidget('open');
  }

  requestProvider(): void {
    this.freshdeskWidgetService.freshworksWidget('prefill', 'ticketForm', {
      subject: $localize`:@@app.feedback.widget.requestProviderSubject:New Healthcare Provider Request`,
      description: $localize`:@@app.feedback.widget.requestProviderDescription:Please add the name of your Healthcare Provider here: `,
    });
    this.freshdeskWidgetService.freshworksWidget('open');
  }

  goToSources(): void {
    this.router.navigate([`${$localize.locale}`, 'linked-sources']);
  }

  private open(): void {
    // Only show one error if there are multiple at a time.
    if (this.modalService.hasOpenModals()) {
      return;
    }

    const modal = this.modalService.open(this.modal, {
      centered: true,
    });

    modal.result.then(
      () => {
        this.store.dispatch(ERROR_UI_ACTIONS.resetErrorMessage());
      },
      () => {
        this.store.dispatch(ERROR_UI_ACTIONS.resetErrorMessage());
      },
    );
  }
}
