import {
  SelfMeasurementsModalBloodGlucoseComponent,
  SelfMeasurementsModalBloodOxygenComponent,
  SelfMeasurementsModalBloodPressureComponent,
  SelfMeasurementsModalHeartRateComponent,
  SelfMeasurementsModalHeightComponent,
  SelfMeasurementsModalRespirationRateComponent,
  SelfMeasurementsModalTemperatureComponent,
  SelfMeasurementsModalWeightComponent,
} from '@self-measurements';
import { AddFitnessAndWellbeingStepContainerComponent } from '../add-modal-steps/add-fitness-wellbeing-step/add-fitness-wellbeing-step.container';
import { AddMeasurementsStepComponent } from '../add-modal-steps/add-measurements-step/add-measurements-step.component';
import { AddMedicalRecordsStepContainerComponent } from '../add-modal-steps/add-medical-records-step/add-medical-records-step.container';
import { AddVitalSignsStepComponent } from '../add-modal-steps/add-vital-signs-step/add-vital-signs-step.component';
import { AddModalMenuItem } from '../add-modal.menu';

/**
 * Gets the next step (Component) for the given menu item.
 *
 * @param menuItem the menu item pressed in the add modal
 */
export function getNextStepFor(menuItem: AddModalMenuItem) {
  switch (menuItem.id) {
    case 'body-weights':
      return SelfMeasurementsModalWeightComponent;
    case 'body-heights':
      return SelfMeasurementsModalHeightComponent;
    case 'body-temperatures':
      return SelfMeasurementsModalTemperatureComponent;
    case 'heart-and-pulse-rates':
      return SelfMeasurementsModalHeartRateComponent;
    case 'glucoses':
      return SelfMeasurementsModalBloodGlucoseComponent;
    case 'blood-pressure':
      return SelfMeasurementsModalBloodPressureComponent;
    case 'respiration':
      return SelfMeasurementsModalRespirationRateComponent;
    case 'o2-saturations':
      return SelfMeasurementsModalBloodOxygenComponent;
    case 'self-measurements':
      return AddMeasurementsStepComponent;
    case 'vital-sign':
      return AddVitalSignsStepComponent;
    case 'medical-records':
      return AddMedicalRecordsStepContainerComponent;
    case 'fitness-wellbeing':
      return AddFitnessAndWellbeingStepContainerComponent;
    default:
      return undefined;
  }
}
