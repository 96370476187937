import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MenuService } from '@navigation/menu';
import { map, Observable } from 'rxjs';
import { AddModalComponent } from './add-modal.component';
import { AddModalMenuItem, isAddModelMenu } from './add-modal.menu';

@Component({
  standalone: true,
  imports: [AddModalComponent, CommonModule],
  selector: 'app-add-button-container',
  template: `<app-add-modal [contextAwareMenu]="(contextAwareMenu$ | async) ?? []"></app-add-modal> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModalContainerComponent {
  readonly contextAwareMenu$: Observable<AddModalMenuItem[]>;
  constructor(menuService: MenuService) {
    this.contextAwareMenu$ = menuService.selectMeasurementMenuItem$.pipe(
      map((menuItem) => (menuItem && isAddModelMenu(menuItem) ? [menuItem] : [])),
    );
  }
}
