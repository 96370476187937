import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem, MenuService } from '@navigation/menu';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import {
  AUTHORIZE_URL_API_ACTIONS,
  IsLoadingAuthorizeUrl,
  selectAccountsCount,
  selectAccountsIsInitializingOrLoading,
  selectIsAuthorizeUrlLoading,
  selectUserAd,
  selectUserAdIsInitializingOrLoading,
} from '@store/digi.me';
import { AnimationLoader, AnimationOptions, LottieComponent, provideLottieOptions } from 'ngx-lottie';
import { combineLatest, map, Observable } from 'rxjs';
import { MiddleMenuComponent } from './middle-menu.component';

@Component({
  standalone: true,
  selector: 'app-middle-menu-container',
  providers: [
    provideLottieOptions({
      player: () => import(/* webpackChunkName: 'lottie-web' */ 'lottie-web'),
    }),
    AnimationLoader,
  ],
  template: ` @if (((isInitializingOrLoading$ | async) ?? true) && (selectedItem$ | async) === null) {
      <div class="d-flex d-lg-none h-100">
        <div class="align-self-center mx-auto">
          <ng-lottie
            [options]="options"
            width="120px"
            height="100px"></ng-lottie>
        </div>
      </div>
    } @else {
      <app-middle-menu
        [selectedItem]="selectedItem$ | async"
        (selected)="navigate($event)"
        [displayName]="(userAd$ | async)?.displayName ?? ' '"
        (onboard)="this.onboard()"
        [isLoadingAuthorizeUrl]="(isLoadingAuthorizeUrl$ | async)?.isLoading ?? false"
        [hasSources]="((numberOfSources$ | async) ?? 0) > 0"></app-middle-menu>
    }`,
  imports: [SharedModule, MiddleMenuComponent, LottieComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiddleMenuContainerComponent {
  readonly selectedItem$: Observable<MenuItem | null> = this.menuService.selectedMenuItemByUrl$;
  readonly isLoadingAuthorizeUrl$: Observable<IsLoadingAuthorizeUrl> = this.store.select(selectIsAuthorizeUrlLoading);
  readonly userAd$ = this.store.select(selectUserAd);
  readonly numberOfSources$: Observable<number | null> = this.store.select(selectAccountsCount);
  readonly isInitializingOrLoading$: Observable<boolean> = combineLatest([
    this.store.select(selectAccountsIsInitializingOrLoading),
    this.store.select(selectUserAdIsInitializingOrLoading),
  ]).pipe(
    map(
      ([accountsIsInitializingOrLoading, userAdIsInitializingOrLoading]) =>
        accountsIsInitializingOrLoading || userAdIsInitializingOrLoading,
    ),
  );

  options: AnimationOptions = {
    path: '/assets/animations/digi-unlock-loop.json',
    loop: true,
  };

  constructor(
    private readonly menuService: MenuService,
    private readonly router: Router,
    private readonly store: Store,
  ) {}

  navigate(item: MenuItem): void {
    this.router.navigate([item.path]);
  }

  onboard() {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({ createNewUser: false, sourceFetch: true, sourceType: 'pull' }),
    );
  }
}
