import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { AUTHORIZE_URL_API_ACTIONS, selectIsAuthorizeUrlLoading } from '@store/digi.me';
import { USER_ACTIONS } from '@store/user';
import { Observable } from 'rxjs';
import { GettingStartedConsentComponent } from './getting-started-consent.component';

@Component({
  standalone: true,
  selector: 'app-getting-started-consent-container',
  imports: [SharedModule, GettingStartedConsentComponent],
  template: `<app-getting-started-consent
    (consent)="consent()"
    (skipLinking)="signUp()"
    [isLoadingAuthorizeUrl]="(this.isLoadingAuthorizeUrl$ | async) ?? false"></app-getting-started-consent>`,
})
export class GettingStartedConsentContainerComponent implements OnInit {
  readonly isLoadingAuthorizeUrl$: Observable<boolean> = this.store.select(selectIsAuthorizeUrlLoading);

  constructor(
    private readonly title: Title,
    private readonly store: Store,
  ) {}

  consent() {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({
        createNewUser: true,
        sourceFetch: true,
        sourceType: 'pull',
      }),
    );
  }

  signUp(): void {
    this.store.dispatch(USER_ACTIONS.signUpWithoutLibClaim());
  }

  ngOnInit(): void {
    this.title.setTitle($localize`:@@app.consent.title:Consent`);
  }
}
