import { Component } from '@angular/core';
import { NavigatorOnLineService } from '@core/services';
import { StorageKeys } from '@globals';
import { Store } from '@ngrx/store';
import { selectMustCreateAccount, selectShowWarningUpdate } from '@store/digi.me';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, combineLatest, map, of } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent {
  online$ = this.navigatorOnlineService.online$;
  readonly showWarningUpdate$ = this.store.select(selectShowWarningUpdate);

  // We should display create user account component when the library has not yet been claimed
  // by the user or when the user is not authenticated and shouldCreateAccount set to true.
  protected readonly mustCreateAccount$: Observable<boolean> = combineLatest([
    this.store.select(selectMustCreateAccount),
    of(Boolean(localStorage.getItem(StorageKeys.SHOULD_CREATE_ACCOUNT))),
    this.oidcSecurityService.isAuthenticated$,
  ]).pipe(
    map(
      ([libraryExists, shouldCreateAccount, authenticatedResult]) =>
        libraryExists || (shouldCreateAccount && !authenticatedResult.isAuthenticated),
    ),
  );

  constructor(
    private readonly store: Store,
    private readonly oidcSecurityService: OidcSecurityService,
    private readonly navigatorOnlineService: NavigatorOnLineService,
  ) {}
}
