<ng-template #useShareProvidersModal
             let-modal>
  <div class="modal-header">
    <h5 i18n="@@app.shareModal.header">Choose a provider you've shared with before or search for another.</h5>
  </div>
  <div class="modal-body gap-1">
    <div class="d-flex flex-column align-items-start gap-3">
      <app-button *ngFor="let provider of pushProvidersList"
                  type="secondary"
                  use="menu"
                  class="w-100"
                  data-cy="btn-share-provider"
                  [label]="provider.serviceProviderName"
                  [rightArrow]="true"
                  (clicked)="share(provider)"></app-button>
      <app-button i18n-label="@@app.shareModal.btn.search"
                  type="secondary"
                  use="menu"
                  class="w-100"
                  label="Search for another Provider"
                  data-cy="btn-share-providers"
                  [rightArrow]="true"
                  (clicked)="share()"></app-button>
    </div>
  </div>
  <div class="modal-footer d-flex flex-column">
    <app-button i18n-label="@@app.shareModal.btn.cancel"
                type="secondary"
                use="block"
                class="w-100"
                label="Cancel"
                data-cy="btn-share-providers"
                (clicked)="close()"></app-button>
  </div>
</ng-template>
