import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SubscriptionService } from '@core/services';
import { PaywallPlan } from '@globals';
import { createSelector, Store } from '@ngrx/store';
import { SharedModule } from '@shared';
import { PAYWALL_VIEW_ACTIONS, selectIsOwned, selectIsPurchasing } from '@store/app';
import { SUMMARY_EXPORT_ACTIONS } from '@store/digi.me';
import { PaywallComponent } from './paywall.component';

@Component({
  standalone: true,
  selector: 'app-paywall-container',
  template: `<app-paywall
    [vm]="vm$ | async"
    (managePurchase)="managePurchase($event)"
    (modalShown)="modalShown()"></app-paywall>`,
  imports: [SharedModule, PaywallComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaywallContainerComponent {
  readonly vm$ = this.store.select(selectPaywallViewModel);

  constructor(
    private readonly store: Store,
    private readonly subscriptionService: SubscriptionService,
  ) {}

  managePurchase(payload: { isProductOwned: boolean; plan: PaywallPlan }) {
    const { isProductOwned, plan } = payload;

    if (isProductOwned) {
      this.store.dispatch(SUMMARY_EXPORT_ACTIONS.pdfExportRequested());
    } else {
      this.subscriptionService.order(plan);
    }
  }

  modalShown(): void {
    this.store.dispatch(PAYWALL_VIEW_ACTIONS.opened());
  }
}

const selectPaywallViewModel = createSelector(selectIsPurchasing, selectIsOwned, (purchasing, owned) => {
  return {
    purchasing,
    owned,
  };
});
