import { Component } from '@angular/core';
@Component({
  selector: 'app-delete-account-progress',
  standalone: true,
  template: `
    <div
      data-cy="account-deletion-progress-modal"
      class="modal-header border-0 p-4">
      <h4
        class="modal-title  fw-bolder"
        i18n="@@app.delete-account.deleting">
        Deleting ...
      </h4>
    </div>
    <div class="modal-body border-0 px-4 py-0">
      <div
        class="progress"
        role="progressbar"
        aria-label="Progress"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated"
          style="width: 100%"></div>
      </div>
    </div>
    <div class="modal-footer border-0 justify-content-between p-4"></div>
  `,
})
export class DeleteAccountProgressModalComponent {}
