import { addIf } from '@globals';
import { createSelector } from '@ngrx/store';
import { selectIsAppleHealthSupported } from '@store/app';
import { selectOnboardedFitnessAppIds, selectUserFitnessIds } from '@store/digi.me';
import { AddModalMenuItem } from '../../add-modal.menu';

export function getAddFitnessAndWellbeingMenu(isAppleHealthSupported: boolean): AddModalMenuItem[] {
  return [
    {
      id: 'fitbit',
      header: $localize`:@@app.SourceType.fitbit:Fitbit`,
      customIconClass: 'fitbit-icon',
    },
    {
      id: 'withings',
      header: $localize`:@@app.SourceType.withings:Withings`,
      customIconClass: 'withings-icon',
    },
    ...addIf(isAppleHealthSupported, {
      id: 'apple-health',
      header: $localize`:@@app.SourceType.appleHealth:Apple Health`,
      customIconClass: 'applehealth-icon',
    } satisfies AddModalMenuItem),
  ];
}

/**
 * For the fitness and wellbeing menu there are criteria whether the menu is shown active.
 * - Apple Health is only shown when supported on the device
 * - Fitness menu items are only shown if they are supported in the backed configuration
 * - Fitness menu is disabled when the user already onboarded that source
 */
export const selectFitnessAndWellbeingMenu = createSelector(
  selectOnboardedFitnessAppIds,
  selectIsAppleHealthSupported,
  selectUserFitnessIds,
  (fitnessAccounts, isAppleHealthSupported, fitnessIds) => {
    // Fitness app support can be turned on or off in the backend, so filter the possible list of all items
    // to only have the supported/enabled apps
    const fitnessMenu = getAddFitnessAndWellbeingMenu(isAppleHealthSupported).filter(
      (menu) => menu.id && menu.id in fitnessIds,
    );

    fitnessMenu.forEach((menu) => {
      // Populate the source ids for the fitness menu items
      menu.sourceId = fitnessIds[menu.id];

      // Fitness apps can already be onboarded, so we disable their state here.
      menu.disabled = fitnessAccounts.includes(menu.sourceId);
    });
    return fitnessMenu;
  },
);
