import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import {
  ButtonComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
  ModalStepperComponent,
} from '@layout';
import { MenuComponent } from '@navigation';
import { IAddModal } from './add-modal.interface';
import {
  AddModalMenuItem,
  getAddSelfMeasurementsAndVitalSignsMenu,
  getAddSourcesAndFitnessMenu,
} from './add-modal.menu';
import { getNextStepFor } from './services/add-modal-step.service';
import { AddModalService } from './services/add-modal.service';

@Component({
  standalone: true,
  selector: 'app-add-modal',
  imports: [
    CommonModule,
    ModalStepperComponent,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    MenuComponent,
  ],
  templateUrl: './add-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddModalComponent implements IAddModal {
  @Input() contextAwareMenu: AddModalMenuItem[] = [];
  @ViewChild(ModalStepperComponent) public stepper!: ModalStepperComponent;

  sourcesAndFitnessMenu = getAddSourcesAndFitnessMenu();
  selfMeasurementsAndVitalSignsMenu = getAddSelfMeasurementsAndVitalSignsMenu();

  nextStepComponentType: any;
  private readonly addModalService = inject(AddModalService);

  constructor() {
    this.addModalService.setModal(this);
  }

  open() {
    this.nextStepComponentType = undefined;
    this.stepper.open();
  }

  selected($event: AddModalMenuItem) {
    const nextStep = getNextStepFor($event);
    if (nextStep) {
      this.nextStepComponentType = nextStep;
    }

    // here we use the stepper, because the service will be defined per stepper created,
    // and not available yet in the component.
    this.stepper.next();
  }

  close() {
    // here we use the stepper, because the service will be defined per stepper created,
    // and not available yet in the component.
    this.nextStepComponentType = undefined;
    this.stepper.close();
  }
}
