import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { selectIsAuthorizeUrlLoading } from '@store/digi.me';
import { Observable } from 'rxjs';
import { AddModalMenuItem, markTriggeringMenuItem } from '../../add-modal.menu';
import { AddFitnessWellbeingStepComponent } from './add-fitness-wellbeing-step.component';
import { selectFitnessAndWellbeingMenu } from './add-fitness-wellbeing-step.menu';

@Component({
  standalone: true,
  imports: [AddFitnessWellbeingStepComponent, CommonModule],
  selector: 'app-add-fitness-and-wellbeing-step-container',
  template: `<app-add-fitness-wellbeing-step
    [menu]="(fitnessAndWellbeingMenu$ | async) ?? []"></app-add-fitness-wellbeing-step> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddFitnessAndWellbeingStepContainerComponent {
  fitnessAndWellbeingMenu$: Observable<AddModalMenuItem[]> = this.store.select(
    selectFitnessAndWellbeingMenuWithLoading,
  );

  constructor(private readonly store: Store) {}
}

const selectFitnessAndWellbeingMenuWithLoading = createSelector(
  selectFitnessAndWellbeingMenu,
  selectIsAuthorizeUrlLoading,
  (menu, authorizeUrlRequest) => {
    markTriggeringMenuItem(menu, authorizeUrlRequest);
    return menu;
  },
);
