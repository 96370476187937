import { assertUnion, OrganizationType } from '@globals';
import { MenuItem } from '@navigation/menu';
import { AuthorizeUrlRequest, IsLoadingAuthorizeUrl } from '@store/digi.me';

const addModalMenuIds = [
  'medical-records',
  'fitness-wellbeing',
  'gp-record',
  'hospital',
  'vaccinations',
  'not-sure',
  'self-measurements',
  'vital-sign',
  'blood-pressure',
  'body-temperatures',
  'heart-and-pulse-rates',
  'glucoses',
  'o2-saturations',
  'respiration',
  'body-weights',
  'body-heights',
  'fitbit',
  'withings',
  'apple-health',
] as const;
export type AddModalMenuIds = (typeof addModalMenuIds)[number];

export function isAddModelMenuId(value: string | undefined | null): value is AddModalMenuIds {
  return addModalMenuIds.includes(value as AddModalMenuIds);
}

export type AddModalMenuItem = MenuItem & {
  id: AddModalMenuIds;
  sourceId?: string;
  organizationType?: OrganizationType;
};

export function isAddModelMenu<T extends MenuItem>(value: T): value is T & AddModalMenuItem {
  return addModalMenuIds.includes(value.id as AddModalMenuIds);
}

/**
 * The trigger can be a request for a:
 * - specific service
 * - an organization type, like hospital, gp
 * - nothing, just show all SAAS sources.
 * @param item the menu item to compare if it needs a loading state
 * @param trigger the triggering authorize request
 * @returns true, if this was the menu item that triggered the request
 */
export function isAuthorizeUrlRequestTriggeredByMenuItem(item: AddModalMenuItem, trigger: AuthorizeUrlRequest | null) {
  const hasSameServiceId = item.sourceId && item.sourceId === trigger?.serviceId;
  const hasSameOrganizationType = item.organizationType && item.organizationType === trigger?.organizationType;
  const hasNothingSpecified =
    !item.sourceId && !trigger?.serviceId && !item.organizationType && !trigger?.organizationType;

  return hasSameServiceId || hasSameOrganizationType || hasNothingSpecified;
}

/**
 * Marks the item in the menu that is the trigger for the IsLoadingAuthorizeUrl request.
 */
export function markTriggeringMenuItem(menu: AddModalMenuItem[], request: IsLoadingAuthorizeUrl) {
  if (request.isLoading) {
    const item = menu.find((item) => isAuthorizeUrlRequestTriggeredByMenuItem(item, request.trigger));
    if (item) {
      item.working = true;
    }
  }
}

/**
 * Gets the menu config.
 *
 * NOTE: This isn't a constant anymore, because we use runtime translations,
 * and therefore the $localize values are undefined when using this as a constant.
 * @returns The menu config
 */
export function getAddSourcesAndFitnessMenu(): AddModalMenuItem[] {
  return [
    {
      id: assertUnion<AddModalMenuIds>('medical-records'),
      header: $localize`:@@app.addModal.record:Medical records`,
      icon: 'health-record',
    },
    {
      id: assertUnion<AddModalMenuIds>('fitness-wellbeing'),
      header: $localize`:@@app.addModal.service:Fitness service`,
      icon: 'fitness-wellbeing',
    },
  ];
}

export function getAddSelfMeasurementsAndVitalSignsMenu(): AddModalMenuItem[] {
  return [
    {
      id: assertUnion<AddModalMenuIds>('self-measurements'),
      header: $localize`:@@app.addModal.measurement:Measurement`,
      icon: 'measurements',
    },
    {
      id: assertUnion<AddModalMenuIds>('vital-sign'),
      header: $localize`:@@app.addModal.reading:Vital reading`,
      icon: 'heart-and-pulse-rates',
    },
  ];
}
