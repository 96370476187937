import { AddModalService } from '@add-modal/services';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType, UserAD } from '@digi.me/models';
import { ObservationStorage, addIfObject, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { BloodGlucoseEntryComponent } from './blood-glucose-entry/blood-glucose-entry.component';
import { BLOOD_GLUCOSE_TEMPLATE } from './blood-glucose-entry/blood-glucose.template';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-blood-glucose',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    HeaderComponent,
    BloodGlucoseEntryComponent,
    SharedModule,
  ],
  templateUrl: './self-measurements-modal-blood-glucose.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalBloodGlucoseComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    bloodGlucoseControl: new FormControl('', Validators.required),
    codeControl: new FormControl('', Validators.required),
    timingControl: new FormControl(''),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(AddModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (!this.form.value['bloodGlucoseControl'] || !this.form.value['codeControl']) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    let bloodGlucose = this.form.value['bloodGlucoseControl'];
    bloodGlucose = bloodGlucose.trim().replace(',', '.');

    const code = this.form.value['codeControl'];
    const timing = this.form.value['timingControl'] ?? undefined;
    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...BLOOD_GLUCOSE_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...BLOOD_GLUCOSE_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...BLOOD_GLUCOSE_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      valueQuantity: {
        ...BLOOD_GLUCOSE_TEMPLATE.valueQuantity,
        value: parseFloat(bloodGlucose!),
      },
      ...addIfObject(!!timing, {
        extension: [
          {
            url: 'http://hl7.org/fhir/StructureDefinition/observation-eventTiming',
            extension: [
              {
                valueCodeableConcept: {
                  coding: [
                    {
                      code: timing,
                      system: 'http://hl7.org/fhir/ValueSet/v3-TimingEvent',
                      display: BloodGlucoseEntryComponent.timingEventCodelijstCodings.find(
                        (coding) => coding.code === timing,
                      )?.display,
                    },
                  ],
                },
                url: 'offset',
              },
            ],
          },
        ],
      }),
      code: {
        coding: [
          {
            code: code!,
            display: BloodGlucoseEntryComponent.glucoseCodelijstCodings.find((coding) => coding.code === code)?.display,
            system: 'http://loinc.org',
          } as r3.Coding,
        ],
      },
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: ObservationStorage.FOLDERS.SELF_MEASUREMENTS,
        measurementType: MeasurementType.bloodGlucose,
      }),
    );

    this.close();
  }

  close() {
    this.onModalClose();
    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      bloodGlucoseControl: '',
      codeControl: '',
      timingControl: '',
      additionalCommentsControl: '',
    });
  }
}
