import { Component, DestroyRef, OnInit, ViewChild, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalModule, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

/**
 * The purpose of an empty component is to replace this component in the production environment.
 * This ensures that a bad actor cannot enable development environments from the client app while in production,
 * serving as a security measure to prevent the exposure of sensitive information.
 */
@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  imports: [SharedModule, NgbModalModule, ButtonComponent],
})
export class FooterComponent implements OnInit {
  @ViewChild('environmentModal', { static: false }) modal: any;

  readonly environments = new Map<string, string>([
    ['Production', 'prod'],
    ['Development', 'dev'],
    ['Integration', 'int'],
    ['Staging', 'stg'],
    ['Test 05', 'test'],
  ]);

  modalRef: NgbModalRef | undefined;

  private readonly destroyRef = inject(DestroyRef);
  private readonly _environmentKey$ = new BehaviorSubject(sessionStorage.getItem('env'));

  constructor(
    private readonly modalService: NgbModal,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
  ) {}

  get environmentKey$(): Observable<string> {
    return this._environmentKey$ as Observable<string>;
  }

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((params: Params) => {
      const env = params['env'] ?? sessionStorage.getItem('env') ?? 'prod';
      const key = [...this.environments.entries()].find(([, value]) => value === env)?.[0];

      this._environmentKey$.next(key ?? 'Production');
    });

    this._environmentKey$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        distinctUntilChanged(),
        map((key: string | null) => key ?? 'Production'),
      )
      .subscribe((key: string) => {
        sessionStorage.setItem('env', this.environments.get(key)!);

        this.router.navigate([], {
          queryParams: { env: this.environments.get(key)! },
          queryParamsHandling: 'merge',
        });
      });
  }

  open(): void {
    this.modalRef = this.modalService.open(this.modal, { centered: true });
  }

  environmentSelected(key: string): void {
    this._environmentKey$.next(key);

    this.modalRef!.close();
  }
}
