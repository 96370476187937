import { environment } from '@environments/environment';
import { addIf, assertUnion } from '@globals';
import { sortMenu } from '@navigation/menu';
import { AddModalMenuIds, AddModalMenuItem } from '../../add-modal.menu';

export function getAddVitalSignsMenu(): AddModalMenuItem[] {
  return [
    ...addIf(environment.features.selfMeasurements.bloodPressure, {
      id: assertUnion<AddModalMenuIds>('blood-pressure'),
      header: $localize`:@@app.menu.bloodPressure:Blood pressure`,
      icon: 'blood-pressure',
    }),
    ...addIf(environment.features.selfMeasurements.bodyTemperature, {
      id: assertUnion<AddModalMenuIds>('body-temperatures'),
      header: $localize`:@@app.menu.bodyTemperature:Body Temperature`,
      icon: 'body-temperatures',
    }),
    ...addIf(environment.features.selfMeasurements.heartAndPulseRates, {
      id: assertUnion<AddModalMenuIds>('heart-and-pulse-rates'),
      header: $localize`:@@app.menu.heartAndPulseRates:Heart and Pulse Rates`,
      icon: 'heart-and-pulse-rates',
    }),
    ...addIf(environment.features.selfMeasurements.glucose, {
      id: assertUnion<AddModalMenuIds>('glucoses'),
      header: $localize`:@@app.menu.glucose:Glucose`,
      icon: 'glucoses',
    }),
    ...addIf(environment.features.selfMeasurements.o2saturations, {
      id: assertUnion<AddModalMenuIds>('o2-saturations'),
      header: $localize`:@@app.menu.o2Saturation:O2 Saturation`,
      icon: 'o2-saturations',
    }),
    ...addIf(environment.features.selfMeasurements.respiratory, {
      id: assertUnion<AddModalMenuIds>('respiration'),
      header: $localize`:@@app.menu.respiration:Respiration`,
      icon: 'respiration',
    }),
  ].sort(sortMenu);
}
