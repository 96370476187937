<ng-template *ngIf="vm()"
             #paywall
             let-modal>
  <div class="modal-header subscription-background d-flex flex-column align-items-start">
    <div class="d-flex flex-row w-100 justify-content-between">
      <h2 class="modal-title font-weight-bold"
          id="modal-basic-title"
          i18n="@@app.paywall.title">Unlock PDF</h2>

      <button type="button"
              class="btn-close"
              aria-label="Close"
              (click)="closeModal()"></button>
    </div>
  </div>

  <div class="modal-body">
    @if(vm()!.owned) {
      <div class="d-flex justify-content-center align-items-center h-100">
        <mat-icon class="text-success"
                  fontIcon="check_circle"></mat-icon>
        <span class="ml-2 text-success font-weight-bold"
              i18n="@@app.paywall.subscribed">Subscribed</span>
      </div>
    } @else if(vm()!.purchasing) {
      <ng-lottie [options]="options"></ng-lottie>
    } @else {
      <div class="d-flex w-100 flex-column justify-content-center">
        <div class="row w-100 mb-3 align-items-center">
          <div class="col-auto">
            <svg class="small-icon">
              <use xlink:href="./assets/images/assets.svg#pdf"></use>
            </svg>
          </div>
          <div class="col ms-3">
            <span i18n="@@app.paywall.feature1">
              Export your Patient Summary as a PDF as often as you like.
            </span>
          </div>
        </div>
        <div class="row w-100 mb-3 align-items-center">
          <div class="col-auto">
            <svg class="small-icon">
              <use xlink:href="./assets/images/assets.svg#share"></use>
            </svg>
          </div>
          <div class="col ms-3">
            <span i18n="@@app.paywall.feature2">Share your Patient Summary via email or text message.</span>
          </div>
        </div>
      </div>

      <div *ngIf="!showRedeemCode"
           class="d-flex w-100 flex-column justify-content-center">
        <app-button class="mb-2"
                    type="secondary"
                    [icon]="selectAnnualPlan() ? 'radio_button_checked' : 'radio_button_unchecked'"
                    (clicked)="togglePaywallPlan('annual')"
                    [label]="annualPrice"
                    use="menu"
                    [badge]="badge"
                    [border]="selectAnnualPlan()"></app-button>
        <app-button type="secondary"
                    [icon]="selectMonthlyPlan() ? 'radio_button_checked' : 'radio_button_unchecked'"
                    (clicked)="togglePaywallPlan('month')"
                    [label]="monthlyPrice"
                    use="menu"
                    [border]="selectMonthlyPlan()"></app-button>
        <p class="text-center"
           i18n="@@app.paywall.agreement">Auto-renews, cancel at any time.</p>
        <a class="text-center link-dark link-primary-hover fw-bolder"
           role="button"
           i18n="@@app.paywall.btn.redeemCode"
           (click)="toggleRedeem()"
           href="javascript:void(0)">Redeem code</a>
      </div>

      <div *ngIf="showRedeemCode">
        <form class="d-flex w-100 flex-column justify-content-center"
              [formGroup]="form">
          <label for="codeInput"
                 class="text-center mb-5 fw-bolder fs-4"><span i18n="@@app.paywall.redeemTitle">Redeem a
              code</span></label>
          <input id="codeInput"
                 type="text"
                 inputmode="decimal"
                 formControlName="codeControl"
                 class="form-control"
                 (input)="sanitizeCodeInput($event.target)"
                 [class.is-invalid]="checkCode()"
                 required />
          <a class="text-center link-dark link-primary-hover mt-3 fw-bolder"
             role="button"
             (click)="toggleRedeem()"
             i18n="@@app.button.cancel"
             href="javascript:void(0)">Cancel</a>
        </form>
      </div>
    }
  </div>

  <div class="modal-footer d-flex flex-column">
    <app-button type="primary"
                use="block"
                class="w-100"
                label="Continue"
                i18n-label="@@app.paywall.btn.continue"
                (clicked)="continue(plan(), vm()!.owned)"></app-button>
    <div class="d-flex w-100 justify-content-between">
      <a target="_blank"
         class="link-dark link-primary-hover ms-5"
         i18n="@@app.paywall.btn.restore"
         (click)="restore()">Restore</a>
      <a [attr.href]="legalsLink"
         target="_blank"
         class="link-dark link-primary-hover me-4"
         i18n="@@app.paywall.btn.legals">Legals</a>
      <a [attr.href]="helpLink"
         target="_blank"
         class="link-dark link-primary-hover me-5"
         i18n="@@app.paywall.btn.help">Help</a>
    </div>
  </div>


</ng-template>