<div class="d-flex justify-content-center">
  <div class="alert alert-warning d-inline-flex align-items-center p-2 mb-0"
       role="alert">
    <svg class="menu-icon"
         aria-hidden="true">
      <use xlink:href="./assets/images/assets.svg#flags"></use>
    </svg><span i18n="@@app.logout.message">You have been logged out for your
      privacy
      and security.</span>
  </div>
</div>