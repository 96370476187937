import { ChangeDetectionStrategy, Component, inject, Input, ViewChild } from '@angular/core';
import { OrganizationType } from '@globals';
import {
  ButtonComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
  ModalStepperComponent,
} from '@layout';
import { MenuComponent } from '@navigation';
import { Store } from '@ngrx/store';
import { AUTHORIZE_URL_API_ACTIONS } from '@store/digi.me';
import { AddModalMenuItem } from '../../add-modal.menu';
import { AddModalService } from '../../services/add-modal.service';

@Component({
  selector: 'app-add-medical-records-step',
  templateUrl: './add-medical-records-step.component.html',
  standalone: true,
  imports: [
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    MenuComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMedicalRecordsStepComponent {
  @ViewChild(ModalStepperComponent) private stepper!: ModalStepperComponent;

  @Input() menu: AddModalMenuItem[] = [];

  private readonly service = inject(AddModalService);

  constructor(private readonly store: Store) {}

  select($event: AddModalMenuItem): void {
    if ($event.organizationType) {
      this.linkSource({ organizationType: $event.organizationType });
    } else if ($event.sourceId) {
      this.linkSource({ serviceId: $event.sourceId.toString() });
    } else {
      this.linkSource();
    }
  }

  close() {
    this.service.close();
  }

  private linkSource(options?: Partial<{ serviceId: string; organizationType: OrganizationType }>): void {
    this.store.dispatch(
      AUTHORIZE_URL_API_ACTIONS.authorizeUrlRequested({
        createNewUser: false,
        sourceFetch: true,
        sourceType: 'pull',
        ...options,
      }),
    );
  }
}
