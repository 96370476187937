import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core';
import { GlobalErrorHandler } from '@core/services';
import { environment } from '@environments/environment';
import { NgbAccordionModule, NgbDropdownModule, NgbOffcanvasModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgIdleModule } from '@ng-idle/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { ActionReducer, INIT, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MARKED_OPTIONS, MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AddModalContainerComponent } from '@add-modal';
import { registerPlugin } from '@capacitor/core';
import { Logger, MixpanelService } from '@logging';
import { APP_ACTIONS } from '@store/app';
import { AbstractLoggerService } from 'angular-auth-oidc-client';
import { OidcLoggingService } from './core/services/oidc-logging.service';

export const webViewSettings = registerPlugin<any>('WebViewSettings');

/**
 * Reset the global state of the ngrx store
 */
export function resetReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return (state: any, action: any) => {
    if (action != null && action.type === APP_ACTIONS.resetGlobalState.type) {
      return reducer(undefined, { type: INIT } as any);
    }

    return reducer(state, action);
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    NgbDropdownModule,
    NgbOffcanvasModule,
    NgbToastModule,
    NgbAccordionModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    StoreModule.forRoot({ router: routerReducer }, { metaReducers: [resetReducer] }),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({ maxAge: 100, logOnly: environment.production, connectInZone: true }),
    StoreRouterConnectingModule.forRoot(),
    NgIdleModule.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MARKED_OPTIONS,
        useFactory: markedOptionsFactory,
      },
    }),
    AddModalContainerComponent,
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: Logger, useClass: MixpanelService },
    { provide: AbstractLoggerService, useClass: OidcLoggingService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// function that returns `MarkedOptions` with renderer override
export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  const linkRenderer = renderer.link;
  renderer.link = (href, title, text) => {
    const html = linkRenderer.call(renderer, href, title, text);
    return html.replace(/^<a /, '<a target="_blank" rel="nofollow" ');
  };

  return {
    renderer: renderer,
    gfm: true,
    breaks: false,
    pedantic: false,
  };
}
