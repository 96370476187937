import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { configId } from '@environments/environment';
import { closeEmbeddedBrowser, StorageKeys } from '@globals';
import { extractErrorFromQueryParams } from '@logging';
import { Store } from '@ngrx/store';
import { APP_ACTIONS } from '@store/app';
import { USER_ACTIONS } from '@store/user';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { filter, from, map, of, share, switchMap, withLatestFrom } from 'rxjs';

@Component({
  selector: 'app-callback',
  template: '',
})
export class CallbackComponent implements OnInit {
  constructor(
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly oidcSecurityService: OidcSecurityService,
  ) {}

  async ngOnInit(): Promise<void> {
    // Log errors returned from Azure AD B2C
    const azureAdB2CError = extractErrorFromQueryParams(this.route.snapshot.queryParamMap);
    if (azureAdB2CError.code && azureAdB2CError.code !== 'AADB2C90091') {
      // Ignore cancels
      this.store.dispatch(APP_ACTIONS.authenticationError({ error: { azureAdB2CError } }));
    }

    await closeEmbeddedBrowser();

    const sharedCheckAuthMultiple = this.oidcSecurityService.checkAuthMultiple().pipe(share());
    sharedCheckAuthMultiple
      .pipe(
        map((responses) => responses.find((response) => response.configId === `${configId}-signup`)),
        filter((response) => response?.isAuthenticated ?? false),
        withLatestFrom(this.oidcSecurityService.getState(`${configId}-signup`)),
        filter(([, state]) => !state.startsWith('lib')),
      )
      .subscribe({
        next: () => {
          this.store.dispatch(USER_ACTIONS.signUpSucceeded());
        },
      });

    sharedCheckAuthMultiple
      .pipe(
        map((responses) => responses.find((response) => response.configId === `${configId}-signup`)),
        filter((response) => response?.isAuthenticated ?? false),
        withLatestFrom(this.oidcSecurityService.getState(`${configId}-signup`)),
        filter(([, state]) => state.startsWith('lib')),
      )
      .subscribe({
        next: ([, state]) => {
          // This flow occurs when user onboard first the service and then signs up.
          // Once the user successfully signs up and logs in, we can remove the state.
          localStorage.removeItem(StorageKeys.SHOULD_CREATE_ACCOUNT);
          this.store.dispatch(APP_ACTIONS.claim({ library: state }));
        },
      });

    sharedCheckAuthMultiple
      .pipe(
        map((responses) =>
          responses.find((response) => response.isAuthenticated && response.configId === `${configId}`),
        ),
        filter((response: LoginResponse | undefined): response is LoginResponse => !!response),
      )
      .subscribe({
        next: (response: LoginResponse) => {
          // The user starts the signup flow on AzureB2C login page. In this flow, the only way to
          // determine if the user is new is by checking the user data from the access token.
          // If the user is not new, we will not send the signup_success event to Mixpanel.
          const isNewUser: boolean = response?.userData?.newUser;
          this.store.dispatch(APP_ACTIONS.authenticated({ isNewUser: isNewUser }));
        },
      });

    sharedCheckAuthMultiple
      .pipe(
        map((responses) => {
          return responses.find((response) => response.isAuthenticated && response.configId === `${configId}-reset`);
        }),
        filter((response: LoginResponse | undefined): response is LoginResponse => !!response),
      )
      .subscribe({
        next: () => {
          // The user has successfully reset their password.
          from(this.router.navigate([`${$localize.locale}`, 'settings'])).pipe(
            switchMap(() => of(APP_ACTIONS.authenticated({ isNewUser: false }))),
          );
        },
      });
  }
}
