import { AddModalService } from '@add-modal/services';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MeasurementType } from '@digi.me/models';
import { ObservationStorage, addIf, addIfObject, addIfProperty } from '@globals';
import { CodeSystems } from '@hl7fhir/codesystems';
import {
  ButtonComponent,
  HeaderComponent,
  ModalStepBodyComponent,
  ModalStepComponent,
  ModalStepFooterComponent,
  ModalStepHeaderComponent,
} from '@layout';
import { Store } from '@ngrx/store';
import { SharedModule, maxDateNowValidator } from '@shared';
import { STORAGE_FILE_API_ACTIONS, selectUser, selectUserAd } from '@store/digi.me';
import * as r3 from 'fhir/r3';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { UserAD } from '../digi.me/models/ad-user.model';
import { RespirationRateEntryComponent } from './respiration-rate-entry/respiration-rate-entry.component';
import { RESPIRATION_TEMPLATE } from './respiration-rate-entry/respiration-rate-entry.template';

@Component({
  standalone: true,
  selector: 'app-self-measurements-modal-respiration-rate',
  imports: [
    CommonModule,
    ModalStepComponent,
    ModalStepHeaderComponent,
    ModalStepBodyComponent,
    ModalStepFooterComponent,
    ButtonComponent,
    HeaderComponent,
    SharedModule,
    RespirationRateEntryComponent,
  ],
  templateUrl: './self-measurements-modal-respiration-rate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelfMeasurementsModalRespirationRateComponent {
  readonly userAd$ = this.store.select(selectUserAd);

  readonly currentDate = moment().format('YYYY-MM-DD HH:mm');

  readonly form = new FormGroup({
    dateControl: new FormControl(this.currentDate, [Validators.required, maxDateNowValidator()]),
    breathsPerMinuteControl: new FormControl('', [Validators.required, Validators.min(0), Validators.max(999)]),
    rhythmControl: new FormControl(''),
    depthControl: new FormControl(''),
    patternControl: new FormControl(''),
    oxygenAdministeredControl: new FormControl(''),
    flowRateControl: new FormControl('', [Validators.min(0.0), Validators.max(50.0)]),
    inspiredValueControl: new FormControl('', [Validators.min(0.0), Validators.max(1.0)]),
    additionalCommentsControl: new FormControl(''),
  });

  readonly user$ = this.store.select(selectUser);

  private service = inject(AddModalService);

  constructor(private readonly store: Store) {}

  onSubmit(userAd: UserAD | null): void {
    if (userAd === null || userAd.patientId === null) {
      return;
    }

    if (!this.form.value['breathsPerMinuteControl']) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    const date = this.form.value['dateControl'];
    const effectiveDateTime = new Date(date!).toISOString();
    const breathsPerMinuteControl = this.form.value['breathsPerMinuteControl'];
    const rhythm = this.form.value['rhythmControl'] ?? undefined;
    const depth = this.form.value['depthControl'] ?? undefined;
    const pattern = this.form.value['patternControl'] ?? undefined;
    const oxygenAdministeredControl = this.form.value['oxygenAdministeredControl'] ?? undefined;
    const flowRateControl = this.form.value['flowRateControl'] ?? undefined;
    let inspiredValueControl = this.form.value['inspiredValueControl'] ?? undefined;
    if (inspiredValueControl) {
      inspiredValueControl = inspiredValueControl.trim().replace(',', '.');
    }

    const comment = this.form.value['additionalCommentsControl'] ?? undefined;

    const observation: r3.Observation = {
      ...RESPIRATION_TEMPLATE,
      id: uuidv4(),
      identifier: [
        {
          system: `${CodeSystems.DIGI_ME}.1.1.1`,
          value: `urn:uuid:${uuidv4()}`,
        },
      ],
      effectiveDateTime,
      subject: {
        ...RESPIRATION_TEMPLATE.subject,
        reference: `urn:uuid:${userAd.patientId}`,
      },
      performer: [
        {
          ...RESPIRATION_TEMPLATE.performer![0],
          reference: `urn:uuid:${userAd.patientId}`,
        },
      ],
      ...addIfProperty(!!comment, 'comment', comment),
      ...addIfObject(!!oxygenAdministeredControl, {
        extension: [
          {
            url: 'http://nictiz.nl/fhir/StructureDefinition/zib-Respiration-AdministeredOxygen',
            extension: [
              {
                url: 'extraOxygenAdministration',
                valueBoolean: oxygenAdministeredControl === 'Y',
              },
              ...addIf(oxygenAdministeredControl === 'Y' && flowRateControl !== undefined && flowRateControl !== '', {
                url: 'flowRate',
                valueQuantity: {
                  value: parseFloat(flowRateControl!),
                  unit: 'l/min',
                  system: 'http://unitsofmeasure.org',
                  code: 'l/min',
                },
              }),
              ...addIf(
                oxygenAdministeredControl === 'Y' && inspiredValueControl !== undefined && inspiredValueControl !== '',
                {
                  url: 'fiO2',
                  valueQuantity: {
                    value: parseFloat(inspiredValueControl!),
                    unit: '{fraction}',
                    system: 'http://unitsofmeasure.org',
                  },
                },
              ),
            ],
          },
        ],
      }),
      component: [
        {
          code: {
            coding: [
              {
                system: 'http://loinc.org',
                code: '9279-1',
                display: 'Respiratory rate',
              },
            ],
          },
          valueQuantity: {
            value: parseFloat(breathsPerMinuteControl!),
            unit: 'breaths per minute',
            system: 'http://unitsofmeasure.org',
            code: '{breaths}/min',
          },
        },
        ...addIf(!!rhythm, {
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '48064009',
                display: 'Respiratory rhythm',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: rhythm,
                display: RespirationRateEntryComponent.ritmeCodelijstCodings.find((coding) => coding.code === rhythm)
                  ?.display,
              },
            ],
          },
        }),
        ...addIf(!!depth, {
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '271626009',
                display: 'Depth of respiration',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: depth,
                display: RespirationRateEntryComponent.diepteCodelijstCodings.find((coding) => coding.code === depth)
                  ?.display,
              },
            ],
          },
        }),
        ...addIf(!!pattern, {
          code: {
            coding: [
              {
                system: 'http://snomed.info/sct',
                code: '278907009',
                display: 'Respiratory pattern',
              },
            ],
          },
          valueCodeableConcept: {
            coding: [
              {
                system: RespirationRateEntryComponent.afwijkendAdemhalingspatroonCodelijstCodings.find(
                  (coding) => coding.code === pattern,
                )?.system,
                code: pattern,
                display: RespirationRateEntryComponent.afwijkendAdemhalingspatroonCodelijstCodings.find(
                  (coding) => coding.code === pattern,
                )?.display,
              },
            ],
          },
        }),
      ],
    };

    // TODO Loader indication
    this.store.dispatch(
      STORAGE_FILE_API_ACTIONS.addStorageFile({
        file: observation,
        fileName: `${observation.id}.json`,
        filePath: ObservationStorage.FOLDERS.SELF_MEASUREMENTS,
        measurementType: MeasurementType.respirationRate,
      }),
    );

    this.close();
  }

  close() {
    this.onModalClose();

    this.service.close();
  }

  onModalClose(): void {
    // Reset the form values when the modal is closed
    this.form.reset({
      dateControl: this.currentDate,
      breathsPerMinuteControl: '',
      rhythmControl: '',
      depthControl: '',
      patternControl: '',
      oxygenAdministeredControl: '',
      flowRateControl: '',
      inspiredValueControl: '',
      additionalCommentsControl: '',
    });
  }
}
