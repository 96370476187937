import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AccountsResponse } from '@digi.me/models';
import { ButtonComponent } from '@layout';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared';

@Component({
  standalone: true,
  selector: 'app-use-share-providers-component',
  templateUrl: './share-providers.component.html',
  imports: [SharedModule, ButtonComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UseShareProvidersComponent implements AfterViewInit {
  @Input() pushProvidersList: AccountsResponse[] | null = [];
  @Output() closeModal = new EventEmitter();
  @Output() shareToProvider = new EventEmitter<AccountsResponse>();
  @ViewChild('useShareProvidersModal', { static: false }) modal!: any;

  modalRef: NgbModalRef | undefined;

  constructor(private readonly modalService: NgbModal) {}

  ngAfterViewInit(): void {
    this.modalRef = this.modalService.open(this.modal, {
      centered: true,
      backdrop: 'static',
    });
  }

  close(): void {
    this.modalRef!.close();
    this.closeModal.emit();
  }

  share(provider?: AccountsResponse): void {
    this.shareToProvider.emit(provider);
  }
}
